<template lang="">
  <Card>
    <div class="row align-items-center mb-5 ml-1">
        <b-button :to="''" class="varlikDurum" variant="warning">Varlık şuan ilanda</b-button> 
        <!-- <router-link > -->
          <!-- <button class="btn btn-primary ml-3">Görüntüle</button> -->
        <!-- </router-link> -->
    </div>
    <template #header> Varlık Bilgileri </template>
    <div class="from-group">
      <label for="">Sahibi *</label>
      <MultiSelect :options="options" :multiple="false" />
    </div>
    <div class="card-sub-header mt-5 mb-5">Gayrimenkul Bilgileri</div>
    <div class="form-group">
      <label for="name">Adı *</label>
      <input type="text" class="form-control" id="name" />
    </div>
    <div class="row">
      <div class="col mb-5">
        <label for="watch" >İzlenecek</label>
        <select name="" id="watch" class="form-control">
          <option value="" selected>Seçiniz</option>
          <option value="">Evet</option>
          <option value="">Hayır</option>
        </select>
      </div>
      <div class="col">
        <label for="property">Varlık Durumu </label>
        <select name="" id="property" class="form-control" required>
          <option value="" selected>Seçiniz</option>
          <option value="">Evet</option>
          <option value="">Hayır</option>
        </select>
      </div>
      <div class="col">
        <label for="bank">Banka Gayrimenkul Kodu</label>
        <input type="text" class="form-control" id="bank" />
      </div>
    </div>
   
    <div class="form-group d-flex">
      <button class="btn btn-success ml-auto">Kaydet</button>
    </div>
  </Card>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {text:"Şekerbank"
      , value:"1"},
        {text:"Odeobank"
      , value:"2"} ],
    };
  },
  components: {
    VarlikList: () => import("@/components/property/DetailTabs/VarlikList"),
  },
};
</script>

<style lang="scss">
.varlikDurum{
  padding: 9px;
  border-radius: 3px;
  font-size: 14px;
  color:white !important;
}
</style>